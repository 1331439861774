<template>
    <div class="d-flex">
        <Sidebar/>
        <div class="component-container-sidebar">
            <h6 class="ms-3 mt-3">Account ID: {{ creatorInfo.AdAccountId.substring(4) }}</h6>
            <h6 class="ms-3">Campaign ID: {{ selectedCampaign.CampaignId }}</h6>
            <h6 class="ms-3">Ad Set ID: {{ selectedAdSet.AdsetId }}</h6>
            <div v-if=displayFormSelection() class="card mb-3 mt-3">
                <div class="card-header">
                    Form Selection
                </div>
                <div class="card-body">
                    <div class="row g-3">
                        <div class="col-12">
                            <label for="form" class="form-label">Select Form</label>
                            <select v-model="selectFormId" class="form-select" id="adForm">
                                <option value="">Please select a Lead Form</option>
                                <option v-for="form in facebookForms" :key=form.key :value=form.id>{{form.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-6">
                            <button type="button" class="btn btn-primary ms-1" aria-disabled="true" @click="selectExistingForm">Select Form</button>
                        </div>
                    </div> 
                </div>
            </div>
            <h3 v-if=displayFormSelection()> - Or - </h3>
            <div class="card mb-3 mt-3" >
                <div class="card-header">
                    Form Creator
                </div>
                <div class="card-body">
                    <form v-if=!displaySuccess class="needs-validation" novalidate>
                        <div v-if="isBuyer()" class="form-row mb-3">
                            <div class="row">
                                <div class="col-md-6">
                                    <label for="location">Location</label>
                                    <input type="text" class="form-control" id="location" v-model="location" placeholder="Location Name" required>
                                    <small class="text-muted">*used for form name only</small>
                                </div>
                                <div class="col-md-6">
                                    <label for="niche">Niche/Ad Type</label>
                                    <input type="text" class="form-control" id="niche" v-model="niche" placeholder="Pools" required>
                                </div>
                            </div>
                        </div>
                        <div v-if="isCinc() && isBuyer()" class="form-row mb-3">
                            <div class="row">
                                <div class="col-md-12">
                                    <label for="savedSearch">Saved Search</label>
                                    <input type="text" class="form-control" id="savedSearch" v-model="savedSearch" placeholder="Saved Search" required>
                                    <small class="text-muted">*used for tracking parameter</small>
                                </div>
                                <div class="col-md-6">
                                    <label for="favoriteCity">Favorite City</label>
                                    <input type="text" class="form-control" id="favoriteCity" v-model="favoriteCity" placeholder="Favorite City" required>
                                    <small class="text-muted">*used for tracking parameter</small>
                                </div>
                                <div class="col-md-6">
                                    <label for="src">Source</label>
                                    <div class="input-group">
                                        <span class="input-group-text">FB_</span>
                                        <input type="text" class="form-control" placeholder="Source" id="src" v-model="source" required @input="validateInput">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row mb-3">
                            <div class="col-md-12">
                                <label for="link">Destination Url</label>
                                <input type="text" class="form-control" id="link" v-model="link" 
                                    placeholder="" required>
                                <small class="text-muted" v-if="isCinc()">* Do not include "?src=SOURCE&fbprompt=1" in the url.</small>
                                <small class="text-muted" v-if="!isCinc()">* Do not include "?fb-lead&utm_source=Real+Geeks&utm_medium=Facebook&utm_campaign=Lead+Ads in the url."</small>
                            </div>
                        </div>
                        <div class="form-row mt-3">
                            <button type="button" class="btn btn-primary ml-1" aria-disabled="true" :disabled=createDisabled @click="createFormRequest" :style="{ 'cursor': createDisabled ? 'wait' : 'pointer' }">
                                <span v-if="createDisabled" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Create Form
                            </button>
                        </div>
                    </form>
                    <div v-if=displaySuccess>
                        <p class="display-1 text-success text-center"><i class="fa fa-check-circle-o"></i></p>
                        <h3 class="text-center text-success mb-4">Success!</h3>
                        <p class="text-center mb-4">The Form has been created!</p>
                        <p class="text-center">
                            <router-link class="btn btn-primary" to="/facebook/creator/ad">Create a New Ad</router-link>
                        </p>
                    </div>
                </div>
            </div>
            <div class="alert alert-info" role="alert" v-if=displayInfo>
                {{ infoMessage }}
            </div>
            <div class="alert alert-danger" role="alert" v-if=displayError>
                {{ errorMessage }}
            </div>
        </div>
    </div>
    
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import { CreatorInfo } from '../../models/CreatorInfo';
import { ValidationResponse } from '../../models/ValidationResponse';
import { FormCreationRequest } from '../../models/FormCreationRequest';
import { Company } from '../../enums/Company';
import Sidebar from './Sidebar.vue';

@Component({
    name: 'FormCreator',
    components:{
        Sidebar
    }
})
export default class FormCreator extends Vue{

    mounted() {
        this.form = document.getElementsByClassName('needs-validation')[0];
    }

    get selectedCampaign() {
        return this.$store.getters['creator/selectedCampaign'];          
    }
    
    get selectedAdSet() {
        return this.$store.getters['creator/selectedAdSet'];
    }

    niche : string = "";
    link: string = "";
    source: string = "";
    favoriteCity: string = "";
    facebookForms : any[] = new Array();
    selectFormId : string = "";
    location: string = "";
    savedSearch: string = "";

    form: any;
    createDisabled: boolean = false;
    displayInfo: boolean = false;
    displayError: boolean = false;
    displaySuccess: boolean = false;
    infoMessage: string = '';
    errorMessage: string = '';

    validation : ValidationResponse = new ValidationResponse();

    get creatorInfo() : CreatorInfo {
        return this.$store.state.creator.creatorInfo
    }

    validateInput() {
        this.source = this.source.replace(/\s/g, '');
    }

    isCinc() : boolean {
        return this.creatorInfo.Company === Company.CINC;
    }
    isBuyer() : boolean {
        return this.selectedCampaign.CampaignType === "Buyer";
    }
    displayFormSelection() : boolean {
        return this.facebookForms.length > 0 && !this.displaySuccess;
    }

    getColumnClass() : string {
        if (this.isCinc() && this.isBuyer()) {
            return "col-md-4";
        }
        return "col-md-6";
    }
  
   
    async created() {
        await this.getExistingForms();

        this.favoriteCity = this.selectedAdSet.Location;
        this.location = this.selectedAdSet.Location;
        this.source = this.selectedAdSet.Location;
        if(this.isCinc()){
            this.link = `https://www.${this.creatorInfo.DomainName}.com/account/facebooklanding`;
        }
            
    }

    async createFormRequest(e: Event) {
        this.displayError = false;
        if(this.form){
            e.preventDefault();
            e.stopPropagation();
            if (this.form.checkValidity() === true) { 
                this.createDisabled = true;
                
                let formCreationRequest : FormCreationRequest = new FormCreationRequest();
                formCreationRequest.Niche = this.niche;
                formCreationRequest.Link = this.link;

                if(this.isBuyer() && this.isCinc())
                    formCreationRequest.Source = `FB_${this.source}`;

                formCreationRequest.FavoriteCity = this.favoriteCity;
                formCreationRequest.Location = this.location;
                formCreationRequest.PageId = this.selectedAdSet.PageId;
                formCreationRequest.CampaignType = this.selectedCampaign.CampaignType;
                formCreationRequest.Company = this.creatorInfo.Company;
                formCreationRequest.ClientFullName = this.creatorInfo.ClientFullName;
                formCreationRequest.DomainName = this.creatorInfo.DomainName;
                formCreationRequest.SavedSearch = this.savedSearch;

                this.displayInfo = true;
                this.infoMessage = "Creating Form...";
                this.createDisabled = true;
                
                await this.$store.dispatch('creator/createCincConfiguredForm', formCreationRequest)
                    .then((response : any) => {
                        this.$store.dispatch('creator/setSelectedFormId', response.data.id);
                        this.$store.dispatch('creator/setActiveItem', response.data.id);
                        this.displaySuccess = true;
                    }).catch((error : any) => {
                        this.displayError = true;
                        this.errorMessage = `An error occurred creating the Facebook form.<br/> ${error}`;
                    }).finally(() => {
                        this.createDisabled = false;
                        this.infoMessage = '';
                        this.displayInfo = false;
                });
            }
            this.form.classList.add('was-validated');
        }
        else{
            this.form = document.getElementsByClassName('needs-validation')[0];
            this.createFormRequest(e);
        }
    }

    async selectExistingForm() {
        await this.$store.dispatch('creator/setSelectedFormId', this.selectFormId);
        this.$router.push({ name : 'adCreator'});
    }

    async getExistingForms() {
        this.$store.dispatch('leadForm/getCompanyFacebookForms',  { fbPageId: this.selectedAdSet.PageId, company: this.creatorInfo.Company})
            .then((response : any) => {
                this.facebookForms = response;
            }).catch((error : any) => {
                this.$toasted.global.error(`An error occurred retrieving the Facebook forms.<br/> ${error}`);
            });
    }
}
</script>
