<template>
    <div class="component-container">
        <div class="row">
            <div class="col-sm-5">
                <div class="display-6 mb-3 mt-3">Ad Template Editor</div>
            </div>
            <div class="col-sm-3 text-end mt-4" v-if="!creationMode">
                <button class="btn btn-secondary" @click="setupCreationUI" ><font-awesome-icon :icon="['fas', 'plus']"/><span class="m-2">New Template</span></button>
            </div>
            <div class="col-sm-3 text-end mt-4" v-else>
                <button class="btn btn-secondary" @click="setupUpdateUI" ><font-awesome-icon :icon="['far', 'pen-to-square']"/><span class="m-2">Edit Templates</span></button>
            </div>
        </div>
        <div class="row" v-if="!creationMode">
            <div class="col-sm-8">
                <div class="form-group mb-3">
                    <label class="m-1" for="adTemplates">Ad Templates</label>
                    <select :disabled="adTemplates.length === 0" class="form-select" id="adTemplates" v-model="selectedTemplateId" @change="loadEditor" >
                        <option value="" disabled>Please Select an Ad Template</option>
                        <option v-for="adTemplate in adTemplates" :key=adTemplate.id :value=adTemplate.id>{{adTemplate.templateName}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div v-if="isTemplateSelected || creationMode">
            <div class="row">
                <div class="col-sm-4 mb-3">
                    <div class="form-group ">
                        <label class="m-1" for="adType">Ad Type</label>
                        <select class="form-select" v-model="templateType">
                            <option value=0>Buyer</option>
                            <option value=1>Team Listing</option>
                            <option value=2>ListCast</option>
                        </select>
                    </div>
                    <small class="m-1 text-muted">Type of ads this template will be available for</small>
                </div>
                <div class="col-sm-4 mb-3" v-if="templateType != 0">
                    <div class="form-group">
                        <label class="m-1" for="adDisplayType">Ad Display</label>
                        <select class="form-select" v-model="displayType">
                            <option value=0>Single Image</option>
                            <option value=1>Carousel</option>
                            <option value=2>Carousel Slideshow</option>
                        </select>
                    </div>
                    <small class="m-1 text-muted">Determines the display of the ad</small>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-5 mb-3">
                    <div class="form-group">
                        <label class="m-1" for="templateName">Template Name</label>
                        <input type="text" id="templateName" class="form-control" v-model="templateName">
                    </div>
                    <small class="m-1 text-muted">Name of the template that is for internal use</small>
                </div>
                <div class="col-sm-3  mb-3">
                    <div class="form-group">
                        <label class="m-1" for="order">Order</label>
                        <input type="text" id="order" class="form-control" v-model="order">
                    </div>
                    <small class="m-1 text-muted">Lower = higher in list</small>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-8  mb-3">
                    <div class="form-group">
                        <label class="m-1" for="adName">Default Ad Name</label>
                        <input type="text" id="adName" class="form-control" v-model="adName">
                    </div>
                    <small class="m-1 text-muted">Default name of Ad that is displayed in facebook</small>
                </div>
                
            </div>
            <div class="row">
                <div class="col-sm-8">
                    <div class="form-group mb-3">
                        <label class="m-1" for="headline">Headline</label>
                        <input type="text" id="headline" class="form-control" v-model="headline">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-8">
                    <div class="form-group mb-3">
                        <label class="m-1" for="description">Description</label>
                        <input type="text" id="description" class="form-control" v-model="description">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-8">
                    <div class="form-group mb-3">
                        <label class="m-1" for="primaryText">Primary Text</label>
                        <textarea class="form-control" id="adPrimaryText" style="height: 150px;" v-model="primaryText"></textarea>
                    </div>
                </div>
            </div>
            <div class="row mt-2" v-if="!creationMode">
                <div class="col-sm-8">
                    <button v-if="!displayDeleteMessage" class="btn btn-primary" :disabled="!isTemplateSelected || isUpdating" @click="updateRequest">Update</button>
                    <button v-if="!displayDeleteMessage" class="float-end btn btn-danger" :disabled="!isTemplateSelected || isUpdating" @click="displayDeleteConfirmation">Delete</button>
                    <div v-else class="alert alert-danger mt-3">
                        <p>Are you sure you want to delete this Ad Template?</p>
                        <button class="btn btn-danger" @click="deleteRequest">Yes</button>
                        <button class="btn btn-secondary" @click="hideDeleteMessage">No</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3" v-if="creationMode">
            <div class="col-sm-8">
                <button class="btn btn-primary" @click="createRequest">Create</button>
            </div>
        </div>
        <div v-if=displaySuccess>
            <div class="row mt-4">
                <div class="col-sm-8 alert alert-success">
                    <p class="display-1 text-success text-center"><font-awesome-icon :icon="['far', 'check-circle']"/></p>
                    <h3 class="text-center text-success m-2">Success!</h3>
                    <p class="text-center m-2">{{successMessage}}</p>
                </div>
            </div>
        </div>
    </div>
</template>


<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import { AdTemplate } from '../../models/AdTemplate';
import { AdTemplateUpdateRequest } from '../../models/request/AdTemplateUpdateRequest';
import { AdTemplateCreateRequest } from '../../models/request/AdTemplateCreateRequest';
@Component({
    name: 'AdTemplateEditor',
    components: {}
})
export default class AdTemplateEditor extends Vue {

    displaySuccess: boolean = false;
    creationMode : boolean = false;
    isUpdating : boolean = false;
    displayDeleteMessage : boolean = false;

    successMessage : string = "";

    templateName : string = "";
    adName : string = "";
    headline : string = "";
    description : string = "";
    primaryText : string = "";
    order : number = 0;
    templateType : number = 0;
    displayType : number = 0;

    adTemplates : AdTemplate[] = [];
    selectedTemplateId : string = "";

    get isTemplateSelected() {
        return this.selectedTemplateId !== "";
    }

    created() {
        this.getAdTexts(false);
    }

    getAdTexts(hardUpdate: boolean = true) {
        this.$store.dispatch('ad/getAllTemplates', hardUpdate)
        .then((response : any) => {
            this.adTemplates = response;
        }).catch((error : any) => {
            this.$toasted.global.error(`An error occurred retrieving the Ad Templates.<br/> ${error}`);
        });
    }

    loadEditor() {
        if (this.selectedTemplateId === "") {
            return;
        }
        let selectedTemplate = this.adTemplates.find((template : AdTemplate) => template.id === this.selectedTemplateId);

        if(selectedTemplate) {
            this.templateName = selectedTemplate.templateName;
            this.adName = selectedTemplate.adName;
            this.headline = selectedTemplate.headline;
            this.description = selectedTemplate.description;
            this.primaryText = selectedTemplate.primaryText;
            this.order = selectedTemplate.order;
            this.templateType = selectedTemplate.templateType;
            this.displayType = selectedTemplate.displayType;
        }
    }

    setupUpdateUI() {
        this.creationMode = false;
    }

    setupCreationUI() {
        this.creationMode = true;
        this.templateName = "";
        this.adName = "";
        this.headline = "";
        this.description = "";
        this.primaryText = "";
        this.order = 0;
        this.templateType = 0;
        this.displayType = 0;
        this.selectedTemplateId = "";
    }

    displayDeleteConfirmation() {
        this.displayDeleteMessage = true;
    }
    hideDeleteMessage() {
        this.displayDeleteMessage = false;
    }

    createRequest() {
        this.isUpdating = true;
        let adTemplate = new AdTemplateCreateRequest();
        adTemplate.templateName = this.templateName;
        adTemplate.adName = this.adName;
        adTemplate.headline = this.headline;
        adTemplate.description = this.description;
        adTemplate.primaryText = this.primaryText;
        adTemplate.order = this.order;
        adTemplate.templateType = this.templateType;

        if(adTemplate.templateType === 0)
            adTemplate.displayType = 0;
        else
            adTemplate.displayType = this.displayType;

        this.$store.dispatch('ad/createTemplate', adTemplate)
        .then(() => {
            this.isUpdating = false;
            this.successMessage = "The Ad Template has been created successfully."
            this.displaySuccess = true;
            this.getAdTexts();
            setTimeout(this.clearSuccess, 4000);
        }).catch((error : any) => {
            this.$toasted.global.error(`An error occurred creating the Ad Template.<br/> ${error}`);
        });
    }

    clearSuccess() {
        this.displaySuccess = false;
    }

    updateRequest() {
        this.isUpdating = true;
        let adTemplate = new AdTemplateUpdateRequest();
        adTemplate.id = this.selectedTemplateId;
        adTemplate.templateName = this.templateName;
        adTemplate.adName = this.adName;
        adTemplate.headline = this.headline;
        adTemplate.description = this.description;
        adTemplate.primaryText = this.primaryText;
        adTemplate.order = this.order;
        adTemplate.templateType = this.templateType;

        if(adTemplate.templateType === 0)
            adTemplate.displayType = 0;
        else
            adTemplate.displayType = this.displayType;

        this.$store.dispatch('ad/updateTemplate', adTemplate)
        .then(() => {
            this.isUpdating = false;
            this.displaySuccess = true;
            this.successMessage = "The Ad Template has been updated successfully."
            this.getAdTexts();
            setTimeout(this.clearSuccess, 4000);
        }).catch((error : any) => {
            this.$toasted.global.error(`An error occurred updating the Ad Template.<br/> ${error}`);
        });
    }

    deleteRequest() {
        this.isUpdating = true;
        this.displayDeleteMessage = false;
        this.$store.dispatch('ad/deleteTemplate', this.selectedTemplateId)
        .then(() => {
            this.isUpdating = false;
            this.successMessage = "The Ad Template has been deleted successfully."
            this.displaySuccess = true;
            this.getAdTexts();
            setTimeout(this.clearSuccess, 4000);
        }).catch((error : any) => {
            this.$toasted.global.error(`An error occurred deleting the Ad Template.<br/> ${error}`);
        });
    }
}
</script>